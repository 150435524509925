import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { Filter, FilteringFeatureNames } from '@ciphr/shared/collections-filtering/models';

export const collectionsFilteringDataAdapterActionsFactory = (featureName: FilteringFeatureNames) =>
  createActionGroup({
    source: featureName,
    events: {
      'Filters Loaded Successfully': props<{ filters: Filter[] }>(),
      'Filters Loading Failed': emptyProps(),
    },
  });
