<ng-container *transloco="let t">
  <section class="main">
    <h1>{{ title() }}</h1>
    <div class="actions">
      <ng-template [cdkPortalOutlet]="actionsAreaPortal()" />
    </div>
    <chr-button-icon class="nav-button" icon="hamburger" palette="neutral" variant="invisible" (chr-action)="showAppNavigation()" />
  </section>

  @if (filteredNavigationItems().length) {
    <chr-tab-group [borderless]="true">
      @for (navigationItem of filteredNavigationItems(); track navigationItem) {
        <chr-tab
          [ciphrLink]="[navigationItem.url]"
          #link="ciphrLink"
          [label]="t(navigationItem.translationKey)"
          [selected]="link.active"
          [attr.data-test-id]="'navigateTo'+navigationItem.translationKey"
          (chr-tab-selected)="selectedTab.set(navigationItem)"
        />
      }
    </chr-tab-group>
  }

  @if (selectedTabItems()?.length) {
    <section class="nav-items" role="menu">
      @for (navigationItem of selectedTabItems(); track navigationItem) {
        <chr-nav-item
          [ciphrLink]="[selectedTab()!.url, navigationItem.url]"
          #link="ciphrLink"
          [label]="t(navigationItem.translationKey)"
          role="menuitem"
          [selected]="link.active"
          size="s"
          [attr.aria-current]="link.active"
          [attr.data-test-id]="'navigate-' + navigationItem.url"
        />
      }
    </section>
  }
</ng-container>

