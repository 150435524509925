import { createActionGroup, props } from '@ngrx/store';

import { HashMap } from '@ngneat/transloco/lib/types';

export const uiActions = createActionGroup({
  source: 'UI Feature',
  events: {
    'Display Success Toast': props<{ toastMessage: string; params?: HashMap; lang?: string }>(),
    'Display Error Toast': props<{ toastMessage: string; params?: HashMap; lang?: string }>(),
  },
});
