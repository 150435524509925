import { ActivatedRoute, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { Directive, HostListener, inject, Input } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[ciphrLink]',
  exportAs: 'ciphrLink',
  hostDirectives: [RouterLink, RouterLinkActive],
})
export class LinkDirective {
  private activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  private routerLink = inject(RouterLink, { self: true });
  private routerLinkActive = inject(RouterLinkActive, { self: true });

  @Input() set ciphrLink(commands: Array<string | number>) {
    this.routerLink.routerLink = commands;
    this.commands = commands;
  }

  private commands: Array<string | number> = [];

  get active(): boolean {
    return this.routerLinkActive.isActive;
  }

  @HostListener('chr-action')
  @HostListener('chr-tab-selected')
  navigate() {
    this.router.navigate(this.commands, { relativeTo: this.activatedRoute });
  }
}
