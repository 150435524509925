import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const timeBeforeValidator = (targetControl: AbstractControl): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const controlDate = new Date(control.value);
    const targetDate = new Date(targetControl.value);

    if (controlDate > targetDate) {
      const maxTime = targetDate.toLocaleTimeString('default', { hour: '2-digit', minute: '2-digit', hour12: true });

      return { timeBefore: { maxTime } };
    }

    return null;
  };
};
