import { AbstractControl, FormControlStatus } from '@angular/forms';
import { distinctUntilChanged, map, Observable, of, startWith, switchMap, takeWhile, timer } from 'rxjs';

export const getInternalStatusChangeOfControl = (control: AbstractControl): Observable<FormControlStatus> =>
  control.statusChanges.pipe(
    startWith(control.status),
    switchMap((status) => {
      if (status !== 'PENDING') return of(status);

      return timer(0, 100).pipe(
        map(() => control.status),
        takeWhile((status) => status === 'PENDING', true),
      );
    }),
    distinctUntilChanged(),
  );
