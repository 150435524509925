import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const fileFormatValidator = (allowedFormats: string[]): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const file: File = control.value;
    if (allowedFormats.includes(file.type)) {
      return null;
    }
    return { fileFormat: true };
  };
};
