import { EnvironmentInjector, EnvironmentProviders, inject, InjectionToken, makeEnvironmentProviders } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpInterceptor, HttpInterceptorFn, HttpRequest } from '@angular/common/http';

import { ApiConfig } from './types/api-config.type';
import { apiDataAdapterBaseUrlInterceptorFactory } from './api-data-adapter-base-url.interceptor';

type IsolatedHttpClientConfig = {
  apiConfig: ApiConfig;
  interceptors?: HttpInterceptorFn[];
  token: InjectionToken<HttpClient>;
};

export const provideIsolatedHttpApiClient = ({ apiConfig, interceptors, token }: IsolatedHttpClientConfig): EnvironmentProviders => {
  return makeEnvironmentProviders([{ provide: token, useFactory: isolatedHttpApiClientFactory(apiConfig, interceptors) }]);
};

const isolatedHttpApiClientFactory = (apiConfig: ApiConfig, isolatedInterceptors?: HttpInterceptorFn[]) => () => {
  const backend = inject(HttpBackend);
  const globalInterceptors = inject(HTTP_INTERCEPTORS, { optional: true });
  const injector = inject(EnvironmentInjector);

  const initialChainFunction: HttpInterceptorFn = (request, handler) => handler(request);

  const interceptors = [
    ...(isolatedInterceptors ?? []),
    (globalInterceptors ?? []).reduceRight(reduceInterceptorClassToChain, initialChainFunction),
    apiDataAdapterBaseUrlInterceptorFactory(apiConfig),
  ];

  const chain = interceptors.reduceRight(interceptorsChainReducerFactory(injector), initialChainFunction);

  return new HttpClient({ handle: (req: HttpRequest<unknown>) => chain(req, (req) => backend.handle(req)) });
};

const reduceInterceptorClassToChain = (chain: HttpInterceptorFn, interceptor: HttpInterceptor): HttpInterceptorFn => {
  return (request, handler) =>
    interceptor.intercept(request, {
      handle: (downstreamRequest) => chain(downstreamRequest, handler),
    });
};

const interceptorsChainReducerFactory = (injector: EnvironmentInjector) => {
  return (next: HttpInterceptorFn, interceptor: HttpInterceptorFn): HttpInterceptorFn =>
    (request, handler) => {
      return injector.runInContext(() => interceptor(request, (downstreamRequest) => next(downstreamRequest, handler)));
    };
};
