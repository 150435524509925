import { ActivatedRoute } from '@angular/router';
import { ChangeDetectionStrategy, Component, OnInit, WritableSignal, computed, inject, input, signal } from '@angular/core';
import { PortalModule } from '@angular/cdk/portal';

import { Store } from '@ngrx/store';
import { TranslocoDirective } from '@ngneat/transloco';

import { ChrComponentsModule } from '@ciphr-design-system/angular';

import { appNavigationFeatureActions } from '@ciphr/core/app-navigation/state';
import { AppPermissionsService } from '@ciphr/core/app-permissions';
import { LinkDirective } from '@ciphr/utils/design-system';

import { PageHeaderNavigationItem } from './page-header-navigation-item.type';
import { PageHeaderService } from './page-header-service';

@Component({
  selector: 'ciphr-page-header',
  standalone: true,
  imports: [TranslocoDirective, ChrComponentsModule, LinkDirective, PortalModule],
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderComponent implements OnInit {
  private activatedRoute = inject(ActivatedRoute);
  private pageHeaderService = inject(PageHeaderService);
  private appPermissionsService = inject(AppPermissionsService);
  private store = inject(Store);

  title = input.required<string>();
  navigationItems = input<PageHeaderNavigationItem[]>([]);

  selectedTab: WritableSignal<PageHeaderNavigationItem | null> = signal(null);
  selectedTabItems = computed(() => this.selectedTab()?.items?.filter((item) => this.appPermissionsService.hasPermission(item.permission)));
  filteredNavigationItems = computed(() =>
    this.navigationItems().filter(
      (navigationItem) => !navigationItem.permission || this.appPermissionsService.hasPermission(navigationItem.permission),
    ),
  );

  actionsAreaPortal = this.pageHeaderService.getActionsAreaPortal();

  ngOnInit(): void {
    if (this.filteredNavigationItems().length) {
      const activeNavigationRoute = this.activatedRoute.firstChild?.snapshot.routeConfig?.path;
      const activeNavigationItem = this.filteredNavigationItems().find((item) => item.url === activeNavigationRoute);

      if (activeNavigationItem) {
        this.selectedTab.set(activeNavigationItem);
      }
    }
  }

  showAppNavigation(): void {
    this.store.dispatch(appNavigationFeatureActions.toggleNavigationVisibility());
  }
}
