export const enTranslation = {
  'BRANDING.SYSTEM_NAME': 'System name and logo',
  'BRANDING.LOGO_SELECTED': 'Current logo',
  'BRANDING.LOGO_REQUIREMENTS_TITLE': 'Requirements for logo assets',
  'BRANDING.LOGO_REQUIREMENTS_DESCRIPTION':
    'Please provide logo with a min height of 100px and min width of 100px. Max file size is 200kb. Allowed file formats: .jpg .png .jpeg .svg .webp',
  'BRANDING.FAVICON': 'Favicon',
  'BRANDING.FAVICON_SELECTED': 'Current favicon',
  'BRANDING.FAVICON_TITLE': 'Requirements for logo assets',
  'BRANDING.FAVICON_DESCRIPTION':
    'Please provide a favicon with size 16px width and 16px height. Max file size is 20kb. Allowed file formats: .jpg .png .jpeg .svg .webp .ico',
  'BRANDING.LOGO_REQUIREMENTS.LABELS.SYSTEM_NAME': 'Human Resources system name',
  'BRANDING.LOGO_REQUIREMENTS.HELPERS.SYSTEM_NAME': 'Max characters: 40',
  'BRANDING.BRAND_BACKGROUND': 'Ciphr Sign In Brand background',
  'BRANDING.BRAND_BACKGROUND_TITLE': 'Requirements for background assets',
  'BRANDING.BRAND_BACKGROUND.DESCRIPTION.RESOLUTION': 'Background graphic file needs to be at least 960px wide and 1080px high',
  'BRANDING.BRAND_BACKGROUND.DESCRIPTION.CENTER': 'The graphic will be always automatically centered on the left side of the screen',
  'BRANDING.BRAND_BACKGROUND.DESCRIPTION.SIZE': 'The files size can not exceed 500kb',
  'BRANDING.BRAND_BACKGROUND.DESCRIPTION.FORMAT': 'Allowed file formats: .jpg .png .jpeg .svg .webp',
  'BRANDING.BRAND_BACKGROUND.ARIA.LOGIN_PHOTO': 'A screen with login form applied to main login page',
  'BRANDING.BRAND_BACKGROUND.ARIA.BACKGROUND': 'A screen with branding photo uploaded by client',
  'BRANDING.BRAND_BACKGROUND.SELECTED_BACKGROUND': 'Preview of current background',
  'BRANDING.INTERFACE_PREVIEW': 'Interface elements preview',
  'BRANDING.PREVIEW.FORM.NEW_TASK': 'New task',
  'BRANDING.PREVIEW.FORM.TASK_NAME': 'Task name',
  'BRANDING.PREVIEW.FORM.PRIORITY': 'Priority',
  'BRANDING.PREVIEW.FORM.CANCEL': 'Cancel',
  'BRANDING.PREVIEW.FORM.ADD_TASK': 'Add task',
  'BRANDING.PREVIEW.LIST.RECRUITMENT': 'Recruitment',
  'BRANDING.PREVIEW.LIST.DASHBOARD': 'Dashboard',
  'BRANDING.PREVIEW.LIST.CALENDAR': 'Calendar',
  'BRANDING.PREVIEW.LIST.PIPELINE': 'Pipeline',
  'BRANDING.PREVIEW.LIST.TASKS': 'Tasks',
  'BRANDING.PREVIEW.LIST.MESSAGES': 'Messages',
  'BRANDING.PREVIEW.SWITCHERS.LIST': 'List',
  'BRANDING.PREVIEW.SWITCHERS.CARDS': 'Cards',
  'BRANDING.PREVIEW.SWITCHERS.CHECKBOX': 'Checkbox label',
  'BRANDING.PREVIEW.SWITCHERS.RADIO': 'Radio label',
  'BRANDING.PREVIEW.SWITCHERS.TOGGLE': 'Toggle label',
  'BRANDING.PREVIEW.BUTTON': 'Button',
  'BRANDING.ACTIONS.DISCARD': 'Discard changes',
  'BRANDING.ACTIONS.SAVE': 'Save',
  'BRANDING.ACTIONS.RELOAD': 'Reload',
  'BRANDING.ACTIONS.SKIP': 'Skip',
  'BRANDING.MESSAGES.SUCCESS.TITLE': 'Branding has been saved successfully',
  'BRANDING.MESSAGES.SUCCESS.DESCRIPTION': "To see the changes in the template, click the 'reload' button.",
  'sso': {
    admin: {
      buttons: {
        details: 'View details',
        changeRole: 'Change role',
        deleteUser: 'Delete user',
        logout: 'Exit',
        profile: 'Profile',
        menuActions: 'User action button',
        resetMultiFactor: 'Reset 2FA',
        disableResetMultiFactor: '2FA disabled',
        resetPassword: 'Reset password',
        blockUser: 'Block user',
        unblockUser: 'Unblock user',
        saveChange: 'Save change',
      },
      header: {
        title: 'Admin page',
        menuButton: 'Main menu',
        links: {
          branding: 'Branding configuration',
          users: 'Users',
          settings: 'Settings',
          singleSignOn: 'Single Sign On',
        },
      },
      users: {
        actions: {
          addUser: 'Add new user',
          createUser: 'Create user',
          save: 'Save changes',
          cancel: 'Cancel',
        },
        header: {
          searchUser: 'Search User',
          searchUserDescription: 'After introduced value results on list will reflect to entered value',
        },
        creation: {
          title: 'Create user',
        },
        details: {
          title: 'User details',
          labels: {
            enabledFrom: 'Enabled from',
            enabledTo: 'Enabled to',
            group: 'Group',
            locked: 'Locked until',
            indefinitely: 'Indefinitely',
            passwordChanged: 'Password changed',
            role: 'Role',
            userName: 'Username',
          },
        },
        edition: {
          title: 'Edit user',
          subTitle: 'Editing for',
        },
        block: {
          title: 'Block user',
          subtitle: 'Are you sure to block this user?',
          labels: {
            username: 'Username',
            date: 'Without end date',
            lockoutEndsOn: 'Locked until',
            placeholder: 'Select date',
          },
        },
        unblock: {
          title: 'Are you sure to active {{ displayName }}',
        },
        list: {
          title: 'Users',
          labels: {
            actions: 'Actions',
            groups: 'Group',
            foreName: 'Forename',
            lastName: 'Surname',
            userName: 'Username',
            role: 'Role',
            status: 'Status',
            enabledFrom: 'Enabled From',
            enabledTo: 'Enabled To',
            lockedTo: 'Blocked Until',
          },
          status: {
            active: 'Active',
            blocked: 'Blocked',
          },
          notFoundUsersTitle: 'No result found',
          notFoundUsersDescription: 'It seems that we did not found what you were looking for',
        },
        changeRole: {
          title: 'Change role',
          labels: {
            userName: 'Username',
            role: 'Role',
            groups: 'Assign groups to the Group Admin',
            groupsExplanation: 'The Group Admin will be able to access accounts assigned to the selected groups',
          },
        },
        deleteUser: {
          title: 'Delete users',
          list: {
            labels: {
              userName: 'User name',
              userRole: 'User role',
            },
          },
        },
        confirmation: {
          resetMfa: 'Are you sure to reset two factor authentication for {{ displayName }} ?',
          resetPassword: 'Are you sure to reset password for {{ displayName }} ?',
        },
        messages: {
          multiFactorResetSuccessfully: 'Multi Factor has been reset successfully',
          passwordChangedSuccessfully: 'Password has been reset successfully',
          roleUpdatedSuccessfully: 'Role has been updated successfully',
          blockSuccessfully: 'User has been blocked successfully',
          unblockSuccessfully: 'User has been unblocked successfully',
          deletedSuccessfully: 'Users have been deleted successfully',
        },
        roles: 'Roles',
        rolesList: {
          BackofficeAdmin: 'Back Office Admin',
          EntityAdmin: 'Entity Admin',
          EntityUserManager: 'Entity User Manager',
          GlobalAdmin: 'Global Admin',
          GlobalUserManager: 'Global User Manager',
          GroupAdmin: 'Group Admin',
          Employee: 'Employee',
        },
      },
      settings: {
        buttons: {
          edit: 'Edit',
          editPasswordPolicy: 'Edit Password Policy',
          editMultiFactorPolicy: 'Edit Two Factor Policy',
          addTenant: 'Add Tenant',
          removeTenant: 'Delete Tenant {{ displayName }}',
        },
        passwordPolicy: {
          dialog: {
            title: 'Password Policy',
            subtitle: 'Password strength',
            labels: {
              passwordExpirationTime: 'Password expiration time',
              suffix: 'days',
              helperMessage: 'Empty value means that password does not expire',
            },
          },
          sectionTitle: 'Password Policy',
          policyStrengthSectionTitle: 'Password policy strength',
          policyStrengthExpireTimeTitle: 'Password will expire after',
          passwordPolicyStrength: {
            weak: 'Weak',
            medium: 'Medium',
            strong: 'Strong',
          },
          passwordPolicyRules: {
            weak: 'At least 8 characters, at least one uppercase letter, at least one special character, at least one lowercase letter, at least one digit.',
            medium:
              'At least 12 characters, at least one uppercase letter, at least one special character, at least one lowercase letter, at least one digit.',
            strong:
              'At least 16 characters, at least one uppercase letter, at least one special character, at least one lowercase letter, at least one digit.',
          },
          messages: {
            passwordPolicyUpdatedSuccessfully: 'Password Policy has been updated successfully',
          },
        },
        multiFactorPolicy: {
          dialog: {
            title: 'Two Factor Policy',
            labels: {
              policyRequired: 'Enforce Two Factor Configuration',
              policyRequiredTextMessageAllowed: 'Allow Text Message Two Factor Authentication',
            },
          },
          sectionTitle: 'Two Factor Policy',
          policyRequired: 'Is Two Factor Authentication required?',
          policyRequiredTextMessageAllowed: 'Is Text Message Two Factor Authentication allowed?',
          booleanStatus: {
            enabled: 'Yes',
            disabled: 'No',
          },
          messages: {
            multiFactorPolicyUpdatedSuccessfully: 'Multi Factor Policy has been updated successfully',
          },
        },
        tenantConfiguration: {
          dialogs: {
            addNewTenant: {
              title: 'Add Tenant',
              labels: {
                azureTenantId: 'Tenant ID',
              },
            },
            removeTenant: {
              title: 'Are you sure to delete tenant {{ displayName }}?',
            },
          },
          sectionTitle: 'Azure ID Tenant configuration',
          list: {
            title: 'Allowed Azure Tenants',
            labels: {
              tenantId: 'Tenant ID',
              enabledOn: 'Enabled On',
              actions: 'Actions',
            },
            empty: {
              iconDescription: 'An empty folder icon',
              description: 'It seems that you don’t have any Azure Tenants right now.',
              title: 'There are no Azure Tenants yet',
            },
          },
          messages: {
            tenantAddedSuccessfully: 'Tenant has been added successfully',
            tenantRemovedSuccessfully: 'Tenant has been deleted successfully',
          },
        },
      },
      identityProviders: {
        buttons: {
          add: 'Add',
          delete: 'Remove {{ displayName }]',
          addIdentityProvider: 'Add Identity Provider',
          context: 'Take an action on {{ provider }}',
          downloadEncryptionCertificate: 'Download encryption certificates',
          downloadSigningCertificate: 'Download signing certificates',
        },
        bindingType: {
          HttpArtifact: 'Http Artifact',
          HttpPost: 'Http Post',
          HttpRedirect: 'Http Redirect',
          Soap: 'Soap',
        },
        dialogs: {
          details: {
            title: 'Identity Provider Configuration',
          },
          removeProvider: {
            title: 'Are you sure to delete {{ displayName }} identity provider ?',
          },
        },
        details: {
          labels: {
            name: 'Name',
            singleSignOnEndpointBindingType: 'Single sign-on binding type',
            singleSignOnEndpointUrl: 'Single sign-on endpoint',
            singleLogoutEndpointBindingType: 'Single logout binding type',
            singleLogoutEndpointUrl: 'Single logout endpoint',
            artifactResolutionEndpointUrl: 'Artifact endpoint url',
            identityProviderEntityId: 'Identity provider entity ID',
            domains: 'Domains',
            base64IdentityProviderCertificates: 'Signing certificates',
            isEnabled: 'Is enabled',
            requireEncryption: 'Require encryption',
            certificates: 'Signing certificates',
          },
        },
        generalInfo: {
          entityId: 'Entity ID',
        },
        list: {
          labels: {
            name: 'Name',
            replyUrl: 'Reply Url',
            enabled: 'Status',
            actions: 'Actions',
            status: {
              enabled: 'Enabled',
              disabled: 'Disabled',
            },
          },
          title: 'Providers',
          empty: {
            iconDescription: 'An empty folder icon',
            description: 'It seems that you don’t have any Providers right now.',
            title: 'There are no Providers yet',
          },
        },
        messages: {
          addedSuccessfully: 'Identity provider has been added successfully',
          updatedSuccessfully: 'Identity provider has been updated successfully',
          deletedSuccessfully: 'Identity provider has been deleted successfully',
        },
      },
      shared: {
        browseFiles: 'Browse files',
        downloadFile: 'Download certificate',
        dropFiles: 'Drops file here to upload',
        removeFile: 'Remove file',
      },
    },
    buttons: {
      logIn: 'Log in',
      backToLogin: 'Back to login',
    },
    errors: {
      ACCOUNT_LOCKED_OUT: 'Your account has been blocked because of too many failed login attempts',
      ACCOUNT_LOCKED_OUT_BY_ADMIN: 'Your account has been blocked because it has been blocked by the Administrator',
      BRANDING_UPDATE_FAILED: 'Update branding operation failed. Please try again. If problem persists contact with administrator',
      BRANDING_INVALID_IMAGE_FILE_TYPE: 'Image type is not matching allowed file types. Please check requirements for more details.',
      BRANDING_INVALID_IMAGE_SIZE: 'Image type exceeded allowed file size. Please check requirements for more details.',
      BRANDING_INVALID_COLOR_HASH: 'Value should match Hex color code eg. #096477',
      CERTIFICATE_IS_NOT_IN_VALID_FORMAT: 'Certificate is not in valid format',
      DATE_MUST_BE_IN_THE_FUTURE: '{{ targetProperty }} date must be in future',
      IDENTITY_ERROR: 'One or more validation errors occurred while updating user information',
      INVALID_ARGUMENT: 'Provided {{ targetProperty }} is not valid',
      INVALID_EMAIL_ADDRESS: 'Provided email address in invalid format',
      INVALID_CREDENTIALS: 'Invalid username or password',
      INVALID_PASSWORD: 'Provided password is invalid',
      ENTITY_ALREADY_EXISTS: 'Entity with given properties already exists',
      EXTERNAL_USER_NOT_FOUND: 'User was not found',
      FORBIDDEN: 'This action can’t be performed',
      GREATER_THAN: '{{ targetProperty }} must be greater than {{ value }}',
      GREATER_THAN_OR_EQUAL: '{{ targetProperty }} must be greater than or equal to {{ value }}',
      PASSWORD_MINIMUM_LENGTH: 'Password does not match minimum length requirement',
      PASSWORD_DIGIT: 'Password does not match digit requirement',
      PASSWORD_NON_ALPHANUMERIC: 'Password does not match non alphanumeric requirement',
      PASSWORDS_ARE_NOT_EQUAL: 'New and confirmed passwords should be equal',
      PASSWORD_UPPERCASE: 'Password does not match uppercase requirement',
      PASSWORD_LOWERCASE: 'Password does not match lowercase requirement',
      PASSWORD_UNQIUE_CHARACTERS: 'Password does not match unique characters requirement',
      PASSWORD_FORBIDDEN: 'Password is in the black list',
      PASSWORD_ALREADY_USED: 'Provided password was already used',
      UNIQUE: 'Property {{ targetProperty }} must be unique. Invalid value: {{ value }} ',
      UNIQUE_COLLECTION: '{{ targetProperty }} with {{ value }} already exists',
      UNKNOWN: 'Unknown error',
      USER_RESET_PASSWORD_FORBIDDEN_FOR_NOT_ACTIVE_ACCOUNT: 'Cannot reset password for inactive account',
      REQUIRED: 'Argument is required',
      RESET_PASSWORD_FORBIDDEN_FOR_NOT_ACTIVE_ACCOUNT: 'Cannot reset password for inactive account ',
      RESET_PASSWORD_FORBIDDEN_BY_ADMIN: 'Cannot reset password for account locked by Administrator',
      ROLE_ASSIGNMENT_FORBIDDEN: 'Role assignment is forbidden',
      INVALID_ABSOLUTE_URL: '{{ targetProperty }} must be valid absolute URL',
      NOT_FOUND: 'Resource was not found',
      VALIDATION_ERROR: 'One or more parameters are not valid',
      TWO_FACTOR_IS_REQUIRED: 'Two factor authentication is required to perform this action',
      TWO_FACTOR_CODE_INVALID: 'Two factor authentication code is invalid',
      TWO_FACTOR_METHOD_DOES_NOT_EXIST: 'Two factor authentication method was not verified',
      TWO_FACTOR_METHOD_ALREADY_DISABLED: 'Two factor authentication is already disabled',
      TWO_FACTOR_ENFORCED: 'Two factor authentication is enforced by tenant',
      UNAUTHORIZED: 'User is not authorized to perform this action',
      UNHANDLED_ERROR: 'An error has occurred',
    },
    multiFactor: {
      buttons: {
        sendAgain: 'Send again',
        verify: 'Verify',
      },
      confirmationDialog: {
        title: 'Confirm authentication method',
        description: {
          phone: 'Please enter your phone number to receive authentication code',
          email: 'Please enter your email address to receive authentication code',
          application: 'Use authentication application to get authentication code',
        },
        qrTitle: 'Scan this QR code with your authentication application',
        followingKey: 'Or enter following key',
        labels: {
          email: 'E-mail',
          phone: 'Phone number',
        },
        validations: {
          email: {
            required: 'E-mail address is required',
            email: 'Invalid e-mail format',
          },
          sms: {
            required: 'Phone number is required',
            mobilePhonePattern: 'Please provide phone number in valid format with country prefix eg. +55123123123',
          },
        },
      },
      methodsDialog: {
        title: 'Configure Two Factor Authentication',
        warningInlineMessageTitle: 'Two factor authentication configuration is required',
        warningInlineMessageDescription: 'Please configure it to log in.',
      },
      verificationDialog: {
        title: {
          application: 'Enter the code from authentication application',
          email: 'Enter the code from e-mail',
          sms: 'Enter the code from text-message',
        },
        description: {
          application: 'Please enter below the code  from your authentication application',
          email: 'Please enter below the code you received',
          sms: 'Please enter below the code you received',
        },
        labels: {
          code: 'Code',
        },
      },
      description: {
        application: 'Please enter your two factor code from authentication application',
        email: 'Please enter your two factor code from  email',
        recoverycode: 'Please enter your recovery code',
        sms: 'Please enter your two factor code from text message',
      },
      messages: {
        multiFactorConfigured: 'Two Factor Authentication has been configured successfully',
        sendAgainEmail: 'An e-mail with code was send, check your inbox and use the form above to confirm your identity',
        sendAgainTextMessage: 'Text message with code was send, please check your mobile phone to confirm your identity',
      },
      rememberBrowser: 'Remember this browser',
      title: 'Two Factor Authentication',
      signInWith: 'Sign in with',
      icon: {
        email: 'An letter icon which present an email option to choose',
        sms: 'Sms icon which present a letter with tree dot inside to choose sms option',
        receoveryCode: 'An icon which present stars inside a letter',
        application: 'An icon which present qr code for application authentication option',
      },
      shared: {
        labels: {
          code: 'Code',
        },
        codeNotReceived: "Didn't receive the code?",
      },
    },
    profile: {
      buttons: {
        addTwoFactorMethod: 'Add 2FA method',
        admin: 'Admin',
        profile: 'Personal Profile',
        changePassword: 'Change Password',
        disable: 'Disable',
        logout: 'Exit',
        reconfigure: 'Reconfigure',
        recoveryCodes: 'Generate recovery codes',
        setDefaultMethod: 'Set as a default method',
        editInformation: 'Edit information',
      },
      disableMultiFactor: {
        dialog: {
          title: 'Disable Two Factor Authentication',
          buttons: {
            disable: 'Disable',
          },
        },
      },
      header: {
        title: 'User profile',
        menuButton: 'Menu button with vertical dots',
      },
      login: {
        lastLogin: 'Last login date',
        loginType: 'Login type',
      },
      password: {
        lastChanged: 'Last changed',
        passwordExpiresOn: 'Password expires on',
      },
      messages: {
        multiFactorAdded: '{{ method }} Two Factor Authentication method has been added successfully',
        multiFactorDisabled: 'Two Factor Authentication {{ method }} method has been disabled successfully',
        multiFactorChanged: 'Preferred method has been changed successfully',
        multiFactorChangedFailed: 'Something went wrong with preferred method change',
        passwordChanged: 'Password has been changed successfully',
      },
      multiFactorAuthentication: {
        activeMethod: 'Active 2FA method',
        configureMethod: 'Configure two factor for {{ method }}',
        defaultLabel: 'Default',
        description: {
          application: 'Scan QR code in the authentication application to confirm your identity',
          email: 'You will receive a code to your email address',
          sms: 'You will receive a code to your phone number',
        },
      },
      recoveryCodes: {
        dialog: {
          title: 'Generate Two Factor Recovery Codes',
          buttons: {
            done: 'Done',
          },
        },
      },
      sections: {
        login: 'Login History',
        password: 'Password',
        recoveryCodes: 'Recovery Codes',
        multiFactorAuthentication: 'Two Factor Authentication',
        mainInformation: 'Main information',
      },
      forms: {
        foreNames: 'User first name',
        lastName: 'Last name',
        title: 'Title',
        knownAs: 'Known as',
        workEmail: 'Work e-mail',
        mobileNumber: 'Mobile phone',
        birthDate: 'Birth date',
      },
      validationMessages: {
        editInfoMessage: 'You can edit this data in your Personal Details area in Ciphr product.',
      },
    },
    logout: {
      description: 'We have safely logged you out. See you later!',
      exitIcon: 'An icon which present exit door',
      actions: {
        backToLogin: 'Back to login page',
      },
    },
    passwordChange: {
      dialog: {
        title: 'Change your password',
        hasPasswordExpiredTitle: 'Your password has expired',
        passwordMatchesPolicyTitle: 'Your password does not match to password policy',
        warningInlineMessageDescription: 'Please enter a new one.',
      },
      labels: {
        currentPassword: 'Current Password',
        newPassword: 'New Password',
        repeatPassword: 'Repeat New Password',
      },
      buttons: {
        change: 'Change',
      },
    },
    newPassword: {
      title: 'Set a new password',
      description: 'Enter your new password here.',
      labels: {
        password: 'Password',
        repeatPassword: 'Repeat password',
        token: 'Token',
        userId: 'User ID',
      },
      buttons: {
        setPassword: 'Set password',
      },
      messages: {
        passwordChanged: 'Password has been updated successfully',
      },
    },
    recaptcha: {
      message: 'Something went wrong with reCaptcha',
    },
    resetPassword: {
      title: 'Reset password',
      description: 'Please provide your email to receive password reset instructions.',
      labels: {
        email: 'E-mail address',
      },
      buttons: {
        send: 'Send',
      },
      confirmationTitle: 'E-mail sent',
      confirmationDescription: 'Please, check your e-mail box.',
      confirmationIconTitle: 'Email box icon',
    },
    regularLogin: {
      buttons: {
        resetPassword: 'Reset password',
      },
      labels: {
        userName: 'E-mail',
        password: 'Password',
        isPersistent: 'Remember my email on this computer',
      },
      title: 'Sign in',
    },
    singleSignOn: {
      buttons: {
        logIn: 'Log in with company identity provider',
      },
      providersLabel: 'or log in with',
      title: 'Single Sign On',
    },
    shared: {
      errorIcon: 'An error icon which present cones',
      defaultErrorMessage: 'An error has occurred.',
      loginBrandingLogoDescription: 'Company logo',
      loginBackgroundPhotoDescription:
        'Picture with company brand identifying the HR system. It may contain pictures, graphics or illustrations related to your company brand. Alternatively it is a default CIPHR illustration of a woman with a mug flying across clouds.',
      poweredBy: 'Powered By',
      poweredByLogoDescription: 'Ciphr company logo',
      noValue: 'No data available',
      noEndDate: 'No end date',
      resultOnPage: 'Results per page',
      multiFactor: {
        labels: {
          application: 'Authentication Application',
          email: 'E-mail',
          recoverycode: 'Recovery Code',
          sms: 'Text Message',
        },
      },
      pagination: {
        results: '{{ pageRange }} of {{ totalLength }} Results',
        noResults: '{{ totalLength }} Results',
      },
      securityReauthorization: {
        dialog: {
          title: 'Confirm your identity',
          subtitle: 'Enter code from your default multi factor authentication method',
        },
      },
      user: {
        form: {
          buttons: {
            editInformation: 'Edit information',
            uploadPhoto: 'Upload photo',
            deletePhoto: 'Delete photo',
            editPhoto: 'Edit photo',
            handleAvatarPhoto: 'Manage avatar photo',
          },
          title: 'Main information',
          labels: {
            forenames: 'User first name',
            surname: 'Last name',
            title: 'Title',
            knownAs: 'Known as',
            email: 'Work e-mail',
            mobileNumber: 'Mobile phone',
            birthDate: 'Birth date',
            recordGroups: 'Groups',
            enabled: 'User enabled',
            enabledFrom: 'Enabled from',
            enabledTo: 'Enabled to',
          },
          confirmationMessage: {
            success: {
              create: 'User has been created successfully',
              update: 'User has been updated successfully',
            },
          },
          validationMessages: {
            editInfoMessage: 'You can edit this data in your Personal Details area in Ciphr product.',
            enabledFrom:
              'Setting an Enabled From date will result in granting user access on that date. Leaving this field empty will result in restricting access.',
            enabledTo: 'Date provided in Enabled to should be later than Enabled from date.',
            defaultGroup: 'New user will be added by default to the SSO General group.',
            fileSize: 'Max file size exceeded ({{ maxFileSize }}kb)',
          },
        },
      },
    },
    filters: {
      roles: {
        label: 'Roles',
        labels: {
          BackofficeAdmin: 'Back Office Admin',
          EntityAdmin: 'Entity Admin',
          EntityUserManager: 'Entity User Manager',
          Employee: 'Employee',
          GlobalAdmin: 'Global Admin',
          GlobalUserManager: 'Global User Manager',
          GroupAdmin: 'Group Admin',
        },
      },
      blockstatuses: {
        label: 'Status',
        labels: {
          Active: 'Active',
          NotActive: 'Blocked',
        },
      },
    },
    validations: {
      required: {
        code: 'Code is required',
        credential: 'Field is required',
        currentPassword: 'Current password field is required',
        newPassword: 'New password field is required',
        newPasswordConfirmed: 'Password confirmation field is required',
        methodControl: 'Authentication method is required. Please select one option',
        password: 'Password field is required',
        passwordConfirmed: 'Password confirmation field is required',
        userName: 'E-mail address is required',
        azureTenantId: 'Tenant ID is required',
        name: 'Name is required',
        lockoutEndsOn: 'Locked until date is required',
        singleSignOnEndpointBindingType: 'Single sign on binding type is required',
        singleSignOnEndpointUrl: 'Single sign on endpoint url is required',
        singleLogoutEndpointUrl: 'Single logout endpoint url is required',
        identityProviderEntityId: 'Identity provider entity id is required',
        domains: 'At least one domain is required',
        base64IdentityProviderCertificates: 'At least one certificate is required',
        artifactResolutionEndpointUrl: 'Artifact resolution endpoint url is required',
        hrSystemName: 'HR system name is required',
      },
      certificateExtension: 'Invalid file format. Allowed formats: {{ fileExtensions }}',
      email: 'Invalid e-mail format',
      password: 'Password must be valid with password policy',
      mobilePhonePattern: 'Please provide phone number in valid format with country prefix eg. +55123123123',
      mismatch: 'New password fields need to match',
      pattern: {
        azureTenantId: 'Invalid format. Please put value in UUIDv4 format',
      },
      maxlength: {
        name: 'Max length is equal to: {{ requiredLength }}',
        hrSystemName: 'Max length for HR system name is {{ requiredLength }}',
      },
      maxFileSize: 'Exceed file size',
      fileSize: 'Exceed file size',
      fileFormat: 'Provide a valid format',
      httpProtocol: {
        singleSignOnEndpointUrl:
          '{{sso.admin.identityProviders.details.labels.singleSignOnEndpointUrl}} have to match to http or https protocol',
        artifactResolutionEndpointUrl:
          '{{sso.admin.identityProviders.details.labels.artifactResolutionEndpointUrl}} have to match to http or https protocol',
        singleLogoutEndpointUrl:
          '{{sso.admin.identityProviders.details.labels.singleLogoutEndpointUrl}} have to match to http or https protocol',
      },
      oldDate: {
        lockoutEndsOn: 'Date must be in future',
      },
    },
    loginHistory: {
      title: 'Login history',
      search: 'Search by IP Address',
      list: {
        labels: {
          status: 'Status',
          loginDate: 'Login Date',
          logoutDate: 'Logout Date',
          ipAddress: 'IP Address',
          browser: 'Browser',
        },
      },
    },
  },
  'NAVIGATION': {
    ADMIN: 'Ciphr Sign In Admin',
    COMPANY_LOGO: 'Company logo',
    DESKTOP: 'Desktop version',
    LOG_OUT: 'Log out',
    PROFILE: 'Personal Profile',
    SEARCH: 'Search',
  },
  'COMMON': {
    PLACEHOLDERS: {
      SEARCH: 'Search...',
    },
    ARIA: {
      INPUTS: {
        AUTOMATIC_SEARCH: 'Search over menu. During typing the search results will be narrowed down to the entered phrase',
      },
      ACTIONS: {
        BACK_BUTTON: 'Back button',
        BULK_BUTTON: 'Bulk actions',
      },
    },
    COLLECTIONS: {
      ARIA: {
        BUTTONS: {
          TOGGLE_DRAWER: 'Toggle filters drawer',
          CLOSE: 'Close filters drawer',
          FILTER_HEADER: 'Filter header',
        },
        SELECT: {
          PAGE_SIZE: 'Page size',
        },
      },
      FILTERING: {
        ARIA_LABELS: {
          TOGGLE_DRAWER: 'Toggle filters drawer',
        },
        APPLIED_FILTERS_NUMBER_BADGE: '{{filtersNumber}} filters applied',
        DRAWER: {
          HEADER: 'Filters',
          ACTIONS: {
            CLEAR_ALL: 'Clear all',
            FILTER: 'Filter',
          },
        },
      },
      HEADERS: {
        SELECT: 'Select',
      },
      MULTI_SELECTION: {
        SELECT_ALL_ON_PAGE: 'Select all on page',
        SELECTION_SIZE: '{{itemsNumber}} selected',
      },
      PAGING: {
        PAGE_SIZE_LABEL: 'results per page',
        RANGE_INFO: '{{pageRange}} of {{totalLength}}',
        RESULTS_INFO: '{{rangeInfo}} results',
      },
      EMPTY: {
        NO_RECORDS: {
          HEADER: 'No result found',
          DESCRIPTION: 'It appears that there are no records in this table',
        },
        NO_RESULTS: {
          HEADER: 'No result found',
          DESCRIPTION: 'It seems that we didn’t find what you were looking for',
        },
      },
    },
  },
  'VALIDATION.REQUIRED': 'Field is required',
  'VALIDATION.EMAIL': 'Field must contain e-mail format',
  'VALIDATION.UNIQUE_EMAIL': 'E-mail must be unique',
  'VALIDATION.PATTERN': 'Provide a valid format',
  'VALIDATION.MIN_DATE': 'Provide a valid date',
  'COMMON.COLLECTIONS.UNSAVED_CHANGES.ACTIONS.DISCARD_CHANGES': 'Discard changes',
  'COMMON.COLLECTIONS.UNSAVED_CHANGES.ACTIONS.RETURN_TO_PAGE': 'Return to page',
  'COMMON.COLLECTIONS.UNSAVED_CHANGES.DESCRIPTION': 'You are about to leave page without saving changes. Are you sure you want to do this?',
  'COMMON.COLLECTIONS.UNSAVED_CHANGES.TITLE': 'Unsaved changes',
  'COMMON.PAGE_NOT_FOUND.DESCRIPTION': "Oops! The page you're looking for seems to have disappeared.",
  'COMMON.PAGE_NOT_FOUND.HEADER': 'Page not found',
  'COMMON.SNACK_BAR.HTTP_ERROR.MESSAGE': 'Something went wrong.',
  'COMMON.UPLOAD.LABELS.FILE_TO_UPLOAD': 'File to upload',
  'COMMON.UPLOAD.LABELS.SELECT_FILE': 'Select file',
};
