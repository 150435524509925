import { createFeature, createReducer, on } from '@ngrx/store';

import { loginApiActions } from './actions/login-api.actions';
import { loginFeatureActions } from './actions/login-feature.actions';
import { SSOHttpErrorResponse } from '../../models/errors.model';
import { TfaSignInProvider } from '../../models/account/tfa-sign-in-provider.enum';

export type LoginStep = 'login' | 'twoFactorAuthentication' | 'passwordChange' | 'twoFactorAuthenticationForce' | '';

export type CoreState = {
  errorMessage: SSOHttpErrorResponse['error'];
  loadingActionState: boolean;
};

export interface LoginState {
  isTwoFactorRequired: boolean;
  hasPasswordExpired: boolean;
  passwordMatchesPolicy: boolean;
  isTwoFactorConfigurationRequired: boolean;
  twoFactorAuthenticationMethods: TfaSignInProvider[];
  defaultTwoFactorAuthenticationMethod: TfaSignInProvider | null;
  isPersistent: boolean;
  returnUrl: string | null;
  loginStep: LoginStep;
  loadingState: boolean;
  sendAgainLoading: boolean;
  errorMessage: SSOHttpErrorResponse['error'];
}

export const initialState: LoginState = {
  isTwoFactorRequired: false,
  passwordMatchesPolicy: true,
  isTwoFactorConfigurationRequired: false,
  hasPasswordExpired: false,
  twoFactorAuthenticationMethods: [],
  defaultTwoFactorAuthenticationMethod: null,
  isPersistent: false,
  returnUrl: null,
  loginStep: 'login',
  errorMessage: null,
  loadingState: false,
  sendAgainLoading: false,
};

export const loginFeature = createFeature({
  name: 'loginFeature',
  reducer: createReducer(
    initialState,
    on(loginFeatureActions.login, loginFeatureActions.loginViaTwoFactor, loginFeatureActions.loginViaSingleSignOn, (state) => ({
      ...state,
      loadingState: true,
    })),
    on(loginFeatureActions.loginViaSingleSignOn, (state) => ({
      ...state,
      errorMessage: null,
    })),
    on(loginApiActions.loginSuccessfully, (state, { signInResponseModel }) => ({
      ...state,
      loadingState: false,
      errorMessage: null,
      isTwoFactorRequired: signInResponseModel.isTwoFactorRequired,
      hasPasswordExpired: signInResponseModel.hasPasswordExpired,
      passwordMatchesPolicy: signInResponseModel.passwordMatchesPolicy,
      isTwoFactorConfigurationRequired: signInResponseModel.isTwoFactorConfigurationRequired,
      twoFactorAuthenticationMethods: signInResponseModel.twoFactorAuthenticationMethods,
      defaultTwoFactorAuthenticationMethod: signInResponseModel.defaultTwoFactorAuthenticationMethod,
      isPersistent: signInResponseModel.isPersistent,
      returnUrl: signInResponseModel.returnUrl,
    })),
    on(loginApiActions.loginFailed, loginApiActions.loginViaTwoFactorFailed, (state, { error }) => ({
      ...state,
      loadingState: false,
      errorMessage: error,
    })),
    on(loginApiActions.loginViaTwoFactorSuccessfully, (state) => ({
      ...state,
      loginStep: 'twoFactorAuthentication' as LoginStep,
      loadingState: false,
    })),
    on(loginFeatureActions.changeTwoFactorAuthenticationMethod, (state, { method }) => ({
      ...state,
      defaultTwoFactorAuthenticationMethod: method,
    })),
    on(loginApiActions.loginViaSingleSignOnSuccessfully, (state) => ({ ...state, loadingState: false })),
    on(loginApiActions.loginViaSingleSignOnFailed, (state, { error }) => ({
      ...state,
      loadingState: false,
      errorMessage: error,
    })),
    on(loginFeatureActions.sendCodeAgain, (state) => ({ ...state, sendAgainLoading: true })),
    on(loginApiActions.sendingCodeAgainSuccessfully, (state) => ({ ...state, sendAgainLoading: false })),
    on(loginApiActions.sendingCodeAgainFailed, (state, { error }) => ({
      ...state,
      sendAgainLoading: false,
      errorMessage: error,
    })),
    on(loginFeatureActions.reCaptchaFailed, (state) => ({ ...state, loadingState: false })),
    on(loginFeatureActions.setLoginStep, (state, { step }) => ({ ...state, loginStep: step })),
    on(loginFeatureActions.clearMessages, (state) => ({ ...state, errorMessage: null })),
    on(loginFeatureActions.clearState, (state) => ({ ...state, ...initialState })),
  ),
});
