import { ModuleWithProviders, NgModule, Type } from '@angular/core';

import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { FilteringFeatureNames } from '@ciphr/shared/collections-filtering/models';

import { COLLECTIONS_FILTERING_DATA_ADAPTER } from './collections-filtering-data-adapter.token';
import { COLLECTIONS_FILTERING_EFFECTS_IDENTIFIER } from './collections-filtering-effects-identifier.token';
import { CollectionsFilteringDataAdapter } from './collections-filtering-data-adapter.interface';
import { CollectionsFilteringEffects } from './collections-filtering.effects';
import { collectionsFilteringReducerFactory } from './collections-filtering.reducer';

type CollectionsFilteringStateModuleConfig<DataAdapterClass> = {
  dataAdapter: Type<DataAdapterClass>;
  filteringFeatureName: FilteringFeatureNames;
};

@NgModule()
export class CollectionsFilteringStateModule {
  static withConfig<Class extends CollectionsFilteringDataAdapter>({
    dataAdapter,
    filteringFeatureName,
  }: CollectionsFilteringStateModuleConfig<Class>): ModuleWithProviders<CollectionsFilteringStateModule> {
    return {
      ngModule: CollectionsFilteringStateModule,
      providers: [
        provideEffects(CollectionsFilteringEffects),
        provideState(filteringFeatureName, collectionsFilteringReducerFactory(filteringFeatureName)),
        { provide: COLLECTIONS_FILTERING_DATA_ADAPTER, useClass: dataAdapter },
        { provide: COLLECTIONS_FILTERING_EFFECTS_IDENTIFIER, useValue: filteringFeatureName },
      ],
    };
  }
}
