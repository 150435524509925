import { Component } from '@angular/core';

import { TranslocoDirective } from '@ngneat/transloco';

import { PageHeaderComponent } from '@ciphr/shared/page-header';
import { ChrComponentsModule } from '@ciphr-design-system/angular';

@Component({
  selector: 'ciphr-page-not-found',
  standalone: true,
  imports: [ChrComponentsModule, TranslocoDirective, PageHeaderComponent],
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {}
