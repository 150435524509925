import { DestroyRef, Directive, ElementRef, inject, OnInit } from '@angular/core';
import { FormGroupDirective, NgControl } from '@angular/forms';

import { distinctUntilChanged, EMPTY, merge } from 'rxjs';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ValidationErrorMessageService } from './validation-error-message.service';

@Directive({
  standalone: true,
  selector: '[ciphrValidationInlineError]',
})
export class ValidationErrorInlineDirective implements OnInit {
  private elementRef = inject(ElementRef);
  private destroyRef = inject(DestroyRef);

  private formGroup = inject(FormGroupDirective, { host: true, optional: true });
  private ngControl = inject(NgControl, { optional: true });

  private validationErrorMessageService = inject(ValidationErrorMessageService);

  ngOnInit() {
    merge(this.formGroup?.ngSubmit.asObservable() ?? EMPTY, this.ngControl?.statusChanges?.pipe(distinctUntilChanged()) ?? EMPTY)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const dsInputNativeElement = this.elementRef.nativeElement;
        const errors = this.ngControl?.errors;

        dsInputNativeElement.error = !!errors && (this.ngControl?.dirty || !!this.formGroup?.submitted);

        if (errors && this.ngControl?.name) {
          const controlName = this.ngControl?.name;

          dsInputNativeElement.explicitValidationMessage = this.validationErrorMessageService.translateValidationError(errors, controlName);
          dsInputNativeElement.error = true;
        } else {
          dsInputNativeElement.explicitValidationMessage = '';
          dsInputNativeElement.error = false;
        }
      });
  }
}
