import { createFeature, createReducer, on } from '@ngrx/store';
import { accountApiActions } from './actions/account-api.actions';
import { accountFeatureActions } from './actions/account-feature.actions';
import { Role } from '../../admin/users/models/roles.enum';

export type AccountType = {
  avatar: string;
  displayName: string;
  id: number;
  isAdmin: boolean;
  language: string;
  orgName: string;
  role: Role | null;
  tenantId: string;
  userName: string;
};

export interface AccountState {
  avatar: string;
  id: number | null;
  userName: string | null;
  displayName: string | null;
  isAdmin: boolean;
  role: Role | null;
  language: string | null;
}

export const initialState: AccountState = {
  avatar: '',
  id: null,
  userName: null,
  displayName: null,
  isAdmin: false,
  role: null,
  language: null,
};

export const accountFeature = createFeature({
  name: 'accountFeature',
  reducer: createReducer(
    initialState,
    on(accountApiActions.loadedAccountInformationSuccessfully, (state, { account }) => ({
      ...state,
      avatar: account.avatar,
      id: account.id,
      role: account.role,
      isAdmin: account.isAdmin,
      language: account.language,
      userName: account.userName,
      displayName: account.displayName,
    })),
    on(accountApiActions.loadedAccountInformationFailed, (state) => ({
      ...state,
      avatar: '',
      id: null,
      userName: '',
      isAdmin: false,
      role: null,
      language: null,
    })),
    on(accountFeatureActions.clearState, (state) => ({
      ...state,
      avatar: '',
      id: null,
      userName: '',
      isAdmin: false,
      role: null,
      language: null,
    })),
  ),
});
