import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const matchValidator =
  (target: string, targetToCompare: string): ValidatorFn =>
  (control: AbstractControl): ValidationErrors | null => {
    if (control) {
      const targetControl = control.get(target);
      const targetToCompareControl = control.get(targetToCompare);

      return targetControl && targetToCompareControl && targetControl.value !== targetToCompareControl.value ? { mismatch: true } : null;
    }

    return null;
  };
