import { Directive, effect, inject, input } from '@angular/core';

import { ChrBadgeDirective } from '@ciphr-design-system/angular';
import { BadgePropertiesMap } from './badge-properties-map.type';

@Directive({
  selector: '[ciphrBadgeProperties]',
  standalone: true,
})
export class BadgePropertiesDirective<Key> {
  private badge = inject(ChrBadgeDirective, { self: true });

  badgeProperties = input.required<BadgePropertiesMap>({ alias: 'ciphrBadgeProperties' });
  key = input.required<Key>();

  constructor() {
    effect(() => {
      const badgeProperties = this.badgeProperties().get(this.key());

      if (badgeProperties) {
        const { palette, variant } = badgeProperties;

        this.badge.palette = palette;
        this.badge.variant = variant;
      }
    });
  }
}
