import { createReducer, on } from '@ngrx/store';

import { ActiveFilter, Filter, FilteringFeatureNames } from '@ciphr/shared/collections-filtering/models';

import { collectionsFilteringDataAdapterActionsFactory } from './actions/collections-filtering-data-adapter.actions';
import {
  collectionsFilteringFeatureActions,
  collectionsFilteringFeatureActionsFactory,
} from './actions/collections-filtering-feature.actions';

export interface CollectionsFilteringState {
  activeFilters: ActiveFilter[];
  availableFilters: Filter[];
  drawerOpened: boolean;
  loading: boolean;
}

export const initialState: CollectionsFilteringState = {
  activeFilters: [],
  availableFilters: [],
  drawerOpened: false,
  loading: false,
};

export const collectionsFilteringReducerFactory = (featureName: FilteringFeatureNames) =>
  createReducer(
    initialState,
    on(collectionsFilteringDataAdapterActionsFactory(featureName).filtersLoadedSuccessfully, (state, { filters }) => {
      return {
        ...state,
        availableFilters: filters,
        loading: false,
      };
    }),
    on(collectionsFilteringDataAdapterActionsFactory(featureName).filtersLoadingFailed, (state) => {
      return {
        ...state,
        loading: false,
      };
    }),
    on(collectionsFilteringFeatureActionsFactory(featureName).clearFilters, (state) => ({
      ...state,
      activeFilters: [],
      drawerOpened: false,
    })),
    on(collectionsFilteringFeatureActionsFactory(featureName).setFilters, (state, { activeFilters }) => ({
      ...state,
      activeFilters,
      drawerOpened: false,
    })),
    on(collectionsFilteringFeatureActionsFactory(featureName).toggleDrawer, (state) => ({
      ...state,
      drawerOpened: !state.drawerOpened,
    })),
    on(collectionsFilteringFeatureActionsFactory(featureName).resetState, () => initialState),
    on(collectionsFilteringFeatureActions.closeDrawer, (state) => ({
      ...state,
      drawerOpened: false,
    })),
  );
