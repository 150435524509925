import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SSOHttpErrorResponse } from '../models/errors.model';
import { SnackBarService } from '@ciphr/ui';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(
    private ngZone: NgZone,
    private snackbarService: SnackBarService,
    private translocoService: TranslocoService,
  ) {}

  handleError(error: unknown) {
    this.ngZone.run(() => {
      if (error instanceof HttpErrorResponse) {
        this.showErrorSnackbar(error);
      } else {
        console.error(error);
      }
    });
  }

  private showErrorSnackbar({ error }: SSOHttpErrorResponse): void {
    if (error && error.error) {
      this.snackbarService.open(this.translocoService.translate(`sso.errors.${error.error.code}`), 'error', 'secondary');
    } else {
      this.snackbarService.open(this.translocoService.translate('sso.shared.defaultErrorMessage'), 'error', 'secondary');
    }
  }
}
