import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AccountState } from './account.reducer';
import { Role } from '../../admin/users/models/roles.enum';

export const accountState = createFeatureSelector<AccountState>('accountFeature');

export const selectCurrentUserId = createSelector(accountState, (state) => state.id);

export const selectUserName = createSelector(accountState, (state) => state.userName);
export const selectDisplayName = createSelector(accountState, (state) => state.displayName ?? '');

export const selectIsAdmin = createSelector(accountState, (state) => state.isAdmin);

export const selectAccountRole = createSelector(accountState, (state) => state.role);

export const selectIsGlobalAdminRole = createSelector(accountState, (state) => state.role === Role.GlobalAdmin);

export const selectAccountAvatar = createSelector(accountState, (state) => state.avatar);
