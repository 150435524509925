import { checkboxesValidator } from './checkboxes.validator';
import { matchValidator } from './match.validator';
import { passwordValidator } from './password.validator';
import { fileSizeValidator } from './file-size.validator';
import { minDateValidator } from './min-date.validator';
import { timeBetweenValidator } from './time-between.validator';
import { timeBeforeValidator } from './time-before.validator';
import { timeAfterValidator } from './time-after.validator';
import { fileFormatValidator } from './file-format.validator';

export class CiphrValidators {
  static match = matchValidator;
  static password = passwordValidator;
  static checkboxes = checkboxesValidator;
  static fileSize = fileSizeValidator;
  static fileFormat = fileFormatValidator;
  static minDate = minDateValidator;
  static timeBetween = timeBetweenValidator;
  static timeBefore = timeBeforeValidator;
  static timeAfter = timeAfterValidator;
}
