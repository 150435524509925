import { InjectionToken } from '@angular/core';

export const DEFAULT_ERRORS_TRANSLATIONS_PATH = new InjectionToken('DEFAULT_ERRORS_TRANSLATIONS_PATH', {
  providedIn: 'root',
  factory: (): string => 'common.validations',
});

export const ErrorMessageTranslationPathProviderToken = new InjectionToken<string>('ErrorMessageTranslationPathProviderToken');

export function provideErrorMessageTranslationPath(path: string) {
  return [
    {
      provide: ErrorMessageTranslationPathProviderToken,
      useValue: path,
    },
  ];
}
