import { inject, Injectable } from '@angular/core';

import { catchError, map, mergeMap, of, tap } from 'rxjs';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { PendoService } from '@ciphr/core/pendo';

import { accountApiActions } from './actions/account-api.actions';
import { accountFeatureActions } from './actions/account-feature.actions';
import { AccountService } from '../../account.service';
import { DocumentService } from '../../core/document.service';

@Injectable({ providedIn: 'root' })
export class AccountEffects {
  private accountService = inject(AccountService);
  private actions$ = inject(Actions);
  private documentService = inject(DocumentService);
  private pendoService = inject(PendoService);
  private store = inject(Store);

  loadAccountInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountFeatureActions.loadAccountInformation),
      mergeMap(() =>
        this.accountService.checkAuth().pipe(
          tap(({ displayName, id, orgName, tenantId, userName }) =>
            this.pendoService.initialize({
              account: {
                id: tenantId,
                name: orgName,
              },
              visitor: {
                email: userName,
                id,
                name: displayName,
              },
            }),
          ),
          map((account) => accountApiActions.loadedAccountInformationSuccessfully({ account })),
          catchError(() => of(accountApiActions.loadedAccountInformationFailed())),
        ),
      ),
    ),
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(accountFeatureActions.logout),
      mergeMap(() => this.accountService.signOut().pipe(map(({ redirectUrl }) => accountApiActions.logoutSuccessfully({ redirectUrl })))),
    ),
  );

  redirectToLogoutAfterSuccessfullyLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(accountApiActions.logoutSuccessfully),
        tap(() => this.store.dispatch(accountFeatureActions.clearState())),
        tap(({ redirectUrl }) => this.documentService.redirectLocationHref(redirectUrl)),
      ),
    { dispatch: false },
  );
}
