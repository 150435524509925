import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const fileSizeValidator = (maxFileSize: number): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const file: File = control.value;
    const maxFileSizeInKB = maxFileSize * 1000;

    if (file.size > maxFileSizeInKB) {
      return { fileSize: { maxFileSize: maxFileSizeInKB } };
    }

    return null;
  };
};
