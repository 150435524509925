import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const minDateValidator = (minDate: Date): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const date = new Date(control.value);

    if (minDate > date) {
      return { minDate: true };
    }

    return null;
  };
};
