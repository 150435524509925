import { Routes } from '@angular/router';

import { provideState } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';

import { resetPasswordFeature } from './reset-password/state/reset-password.reducer';
import { ResetPasswordEffects } from './reset-password/state/reset-password.effects';
import { newPasswordFeature } from './new-password/state/new-password.reducer';
import { NewPasswordEffects } from './new-password/state/new-password.effects';
import { loginGuard, newPasswordGuard, profileGuard } from './auth.guard';
import { adminGuard } from './admin/admin.guard';
import { PageNotFoundComponent } from '@ciphr/shared/page-not-found';

export const COMPANY_PREFIX = 'CIPHR';

export const appRoutes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule),
    canActivate: [loginGuard],
    title: `Login page - ${COMPANY_PREFIX}`,
  },
  {
    path: 'reset-password',
    loadComponent: () => import('./reset-password/reset-password.component').then((c) => c.ResetPasswordComponent),
    providers: [provideState(resetPasswordFeature), provideEffects([ResetPasswordEffects])],
    title: `Reset password page - ${COMPANY_PREFIX}`,
  },
  {
    path: 'new-password',
    loadComponent: () => import('./new-password/new-password.component').then((c) => c.NewPasswordComponent),
    providers: [provideState(newPasswordFeature), provideEffects([NewPasswordEffects])],
    canActivate: [newPasswordGuard],
    title: `Set new password page - ${COMPANY_PREFIX}`,
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
    canMatch: [profileGuard],
    title: `Profile page - ${COMPANY_PREFIX}`,
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canMatch: [adminGuard],
    title: `Admin page - ${COMPANY_PREFIX}`,
  },
  {
    path: 'error',
    loadComponent: () => import('./error/error.component').then((c) => c.ErrorComponent),
    title: `Error page - ${COMPANY_PREFIX}`,
  },
  {
    path: 'logout',
    loadComponent: () => import('./logout/logout.component').then((c) => c.LogoutComponent),
    canActivate: [loginGuard],
    title: `Logout page - ${COMPANY_PREFIX}`,
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  { path: '**', component: PageNotFoundComponent },
];
