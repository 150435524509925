import { inject } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, CanActivateFn, CanMatchFn, Router, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectUserName } from './state/account/account.selectors';

export const loginGuard: CanActivateFn = (): Observable<boolean | UrlTree> => {
  const store = inject(Store);
  const router = inject(Router);

  return store.select(selectUserName).pipe(map((userName) => (userName !== '' ? router.createUrlTree(['profile']) : true)));
};

export const profileGuard: CanMatchFn = (): Observable<boolean | UrlTree> => {
  const store = inject(Store);
  const router = inject(Router);

  return store.select(selectUserName).pipe(map((userName) => (userName !== '' ? true : router.createUrlTree(['login']))));
};

export const newPasswordGuard: CanActivateFn = (route: ActivatedRouteSnapshot): boolean | UrlTree => {
  const router = inject(Router);

  return route.queryParamMap.get('userId') && route.queryParamMap.get('token') ? true : router.createUrlTree(['login']);
};
