import { inject, Injectable } from '@angular/core';

import { catchError, filter, first, map, mergeMap, throwError } from 'rxjs';

import { Actions, createEffect, ofType, OnIdentifyEffects } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { COLLECTIONS_FILTERING_DATA_ADAPTER } from './collections-filtering-data-adapter.token';
import { COLLECTIONS_FILTERING_EFFECTS_IDENTIFIER } from './collections-filtering-effects-identifier.token';
import { collectionsFilteringDataAdapterActionsFactory } from './actions/collections-filtering-data-adapter.actions';
import {
  collectionsFilteringFeatureActions,
  collectionsFilteringFeatureActionsFactory,
} from './actions/collections-filtering-feature.actions';
import { selectFiltersDrawerOpenedState } from './collections-filtering.selectors';

@Injectable()
export class CollectionsFilteringEffects implements OnIdentifyEffects {
  private actions$ = inject(Actions);
  private dataAdapter = inject(COLLECTIONS_FILTERING_DATA_ADAPTER);
  private effectsIdentifier = inject(COLLECTIONS_FILTERING_EFFECTS_IDENTIFIER);
  private store = inject(Store);

  dispatchDrawerOpenedAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(collectionsFilteringFeatureActionsFactory(this.effectsIdentifier).toggleDrawer),
      mergeMap(() => this.store.select(selectFiltersDrawerOpenedState(this.effectsIdentifier)).pipe(first(), filter(Boolean))),
      map(() => collectionsFilteringFeatureActions.drawerOpened()),
    ),
  );

  loadFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(collectionsFilteringFeatureActionsFactory(this.effectsIdentifier).loadFilters),
      mergeMap(({ params }) =>
        this.dataAdapter.fetchFilters(...params).pipe(
          map((filters) => collectionsFilteringDataAdapterActionsFactory(this.effectsIdentifier).filtersLoadedSuccessfully({ filters })),
          catchError((httpErrorResponse) => {
            this.store.dispatch(collectionsFilteringDataAdapterActionsFactory(this.effectsIdentifier).filtersLoadingFailed());
            return throwError(() => httpErrorResponse);
          }),
        ),
      ),
    ),
  );

  ngrxOnIdentifyEffects() {
    return this.effectsIdentifier;
  }
}
