import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders, Provider } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ApiConfig } from './types/api-config.type';
import { provideHttpApiClient } from './http-api-client.provider';

enum ApiDataAdapterProvider {
  ExistingHttpApiClient,
  IsolatedHttpApiClient,
}

interface ApiDataAdapterFeature<Feature extends ApiDataAdapterProvider> {
  feature: Feature;
  providers: Array<Provider | EnvironmentProviders>;
}

const makeApiDataAdapterFeature = <Feature extends ApiDataAdapterProvider>(
  feature: Feature,
  providers: Array<Provider | EnvironmentProviders>,
): ApiDataAdapterFeature<Feature> => ({
  feature,
  providers,
});

export const provideApiDataAdapter = (
  apiDataAdapterService: Provider,
  ...features: ApiDataAdapterFeature<ApiDataAdapterProvider>[]
): EnvironmentProviders => {
  const providers: Array<Provider | EnvironmentProviders> = [apiDataAdapterService];

  for (const feature of features) {
    providers.push(...feature.providers);
  }

  return makeEnvironmentProviders(providers);
};

export const withExistingHttpApiClient = <HttpApiClient>(
  httpApiClientToken: InjectionToken<HttpClient>,
  existingHttpApiClient: HttpApiClient,
): ApiDataAdapterFeature<ApiDataAdapterProvider.ExistingHttpApiClient> =>
  makeApiDataAdapterFeature(ApiDataAdapterProvider.ExistingHttpApiClient, [
    {
      provide: httpApiClientToken,
      useExisting: existingHttpApiClient,
    },
  ]);

export const withIsolatedHttpApiClient = (
  httpApiClientToken: InjectionToken<HttpClient>,
  apiConfig: ApiConfig,
): ApiDataAdapterFeature<ApiDataAdapterProvider.IsolatedHttpApiClient> =>
  makeApiDataAdapterFeature(ApiDataAdapterProvider.IsolatedHttpApiClient, [provideHttpApiClient(httpApiClientToken, apiConfig)]);
