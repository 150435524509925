import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const timeAfterValidator = (targetControl: AbstractControl): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const controlDate = new Date(control.value);
    const targetDate = new Date(targetControl.value);

    if (controlDate < targetDate) {
      const minTime = targetDate.toLocaleTimeString('default', { hour: '2-digit', minute: '2-digit', hour12: true });

      return { timeAfter: { minTime } };
    }

    return null;
  };
};
