import { Injectable, inject, computed } from '@angular/core';
import { EMPTY, Observable, catchError, forkJoin, iif, of, tap } from 'rxjs';
import { BRANDING_API_CLIENT, BRANDING_BACKGROUND_PHOTO } from './app-branding.provider';
import { patchState, signalState } from '@ngrx/signals';

type BrandingState = {
  logo: Blob | null;
  favicon: Blob | null;
  backgroundPhoto: Blob | null;
};

@Injectable({ providedIn: 'root' })
export class AppBrandingService {
  private readonly httpClient = inject(BRANDING_API_CLIENT);
  private readonly brandingBackground = inject(BRANDING_BACKGROUND_PHOTO);

  private readonly brandingState = signalState<BrandingState>({ logo: null, favicon: null, backgroundPhoto: null });

  brandingLogo = computed(() => {
    const logo = this.brandingState.logo();
    return logo ? URL.createObjectURL(logo) : null;
  });
  brandingLogoFile = this.brandingState.logo;

  brandingBackgroundPhoto = computed(() => {
    const backgroundPhoto = this.brandingState.backgroundPhoto();
    return backgroundPhoto ? URL.createObjectURL(backgroundPhoto) : null;
  });
  brandingBackgroundPhotoFile = this.brandingState.backgroundPhoto;

  brandingFavicon = computed(() => {
    const favicon = this.brandingState.favicon();
    return favicon ? URL.createObjectURL(favicon) : null;
  });
  brandingFaviconFile = this.brandingState.favicon;

  branding$ = forkJoin([
    iif(() => this.brandingBackground, this.fetchBrandingBackgroundPhoto(), of(EMPTY)),
    this.fetchBrandingLogo(),
    this.fetchBrandingTheme(),
    this.fetchBrandingFavicon(),
  ]);

  private fetchBrandingBackgroundPhoto(): Observable<Blob | string> {
    return this.httpClient.get('/static/branding/background-photo', { responseType: 'blob' }).pipe(
      tap((backgroundPhoto: Blob) => patchState(this.brandingState, { backgroundPhoto })),
      catchError(() => of('')),
    );
  }

  private fetchBrandingLogo(): Observable<Blob | string> {
    return this.httpClient.get('/static/branding/logo', { responseType: 'blob' }).pipe(
      tap((logo: Blob) => patchState(this.brandingState, { logo })),
      catchError(() => of('')),
    );
  }

  private fetchBrandingTheme(): Observable<string> {
    return this.httpClient.get('/static/branding/theme', { responseType: 'text' }).pipe(
      tap((theme: string) => this.overrideTheme(theme)),
      catchError(() => of('')),
    );
  }

  private fetchBrandingFavicon(): Observable<Blob | string> {
    return this.httpClient.get('/static/branding/favicon', { responseType: 'blob' }).pipe(
      tap((favicon: Blob) => patchState(this.brandingState, { favicon })),
      tap((favicon: Blob) => this.overrideFavicon(URL.createObjectURL(favicon))),
      catchError(() => of('')),
    );
  }

  private overrideTheme(theme: string): void {
    theme = theme.replace(/:root/g, 'body.chr-ciphr-light');
    const style = document.createElement('style');
    const head = document.head || document.getElementsByTagName('head')[0];
    head.appendChild(style);
    style.appendChild(document.createTextNode(theme));
  }

  overrideFavicon(faviconLink: string): void {
    const favicon = document.querySelector<HTMLLinkElement>('link[rel="icon"]');
    favicon && (favicon.href = faviconLink);
  }
}
