import { inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { AuthPanelService } from '@ciphr/core/app-navigation/features';

import { accountFeatureActions } from '../state/account/actions/account-feature.actions';
import { selectDisplayName, selectIsAdmin } from '../state/account/account.selectors';

@Injectable({ providedIn: 'root' })
export class SsoAuthPanelService implements AuthPanelService {
  private readonly store = inject(Store);

  readonly isSsoAdmin = this.store.selectSignal(selectIsAdmin);
  readonly username = this.store.selectSignal(selectDisplayName);

  logOut(): void {
    this.store.dispatch(accountFeatureActions.logout());
  }
}
