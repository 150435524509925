import { createFeatureSelector, createSelector } from '@ngrx/store';

import { LoginState } from './login.reducer';

export const loginState = createFeatureSelector<LoginState>('loginFeature');

export const selectLoginState = createSelector(loginState, (state) => state.loginStep);
export const selectLoading = createSelector(loginState, (state) => state.loadingState);
export const selectSendAgainLoading = createSelector(loginState, (state) => state.sendAgainLoading);
export const selectReturnUrl = createSelector(loginState, (state) => state.returnUrl);
export const selectErrorMessage = createSelector(loginState, (state) => state.errorMessage);
export const selectActiveTwoFactorMethod = createSelector(loginState, (state) => state.defaultTwoFactorAuthenticationMethod);
export const selectIsPersistent = createSelector(loginState, (state) => state.isPersistent);
export const selectTwoFactorMethods = createSelector(loginState, (state) => state.twoFactorAuthenticationMethods);

export const selectPasswordForce = createSelector(loginState, (state) => state.hasPasswordExpired || !state.passwordMatchesPolicy);

export const selectStepConfiguration = createSelector(loginState, (state) => ({
  isTwoFactorConfigurationRequired: state.isTwoFactorConfigurationRequired,
  hasPasswordExpired: state.hasPasswordExpired,
  passwordMatchesPolicy: state.passwordMatchesPolicy,
  returnUrl: state.returnUrl,
}));
