import { inject, Injectable } from '@angular/core';
import { filter, Observable, tap } from 'rxjs';

import { SsoCustomConfig } from '../core/models/custom-config';
import { CUSTOM_APP_CONFIG } from '../core/factories/custom-config-initializer-factory';

@Injectable({ providedIn: 'root' })
export class RecaptchaService {
  private _recaptchaSiteKeyV3 = '';

  appConfig = inject(CUSTOM_APP_CONFIG);

  get reCaptchaSiteKey(): string {
    return this._recaptchaSiteKeyV3;
  }

  public configureReCaptchaSiteKey(): Observable<SsoCustomConfig> {
    return this.appConfig.asObservable().pipe(
      filter(Boolean),
      tap(({ reCaptchaSiteKey }) => (this._recaptchaSiteKeyV3 = reCaptchaSiteKey)),
    );
  }
}
