import { Directive, effect, inject, input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { DEFAULT_ERRORS_TRANSLATIONS_PATH, ErrorMessageTranslationPathProviderToken } from './validation.types';
import { TranslocoService } from '@ngneat/transloco';

type ValidationErrorContext = {
  $implicit: Observable<string>;
};

@Directive({ selector: '[ciphrValidationError]', standalone: true })
export class DeprecatedValidationErrorDirective {
  private defaultErrorMessagesTranslationsPath = inject(DEFAULT_ERRORS_TRANSLATIONS_PATH);
  private errorMessageTranslationPathProviderToken = inject(ErrorMessageTranslationPathProviderToken, { optional: true });
  private templateRef = inject(TemplateRef<ValidationErrorContext>);
  private translocoService = inject(TranslocoService);
  private viewContainerRef = inject(ViewContainerRef);

  control = input<AbstractControl | undefined>(undefined, { alias: 'ciphrValidationErrorFrom' });
  controlName = input<string | undefined>(undefined, { alias: 'ciphrValidationErrorControlName' });

  constructor() {
    effect(() => {
      const control = this.control();

      this.viewContainerRef.clear();

      if (control?.errors && this.controlName()) {
        const [errorKey, errorParams] = Object.entries(control.errors)[0];

        const translations = this.translocoService.getTranslation(this.translocoService.getActiveLang());
        const translation = translations[`${this.errorMessageTranslationPathProviderToken}.${errorKey}.${this.controlName()}`];

        const translatedErrorMessage$ = this.errorMessageTranslationPathProviderToken
          ? translation
            ? this.translocoService.selectTranslate(
                `${this.errorMessageTranslationPathProviderToken}.${errorKey}.${this.controlName()}`,
                errorParams,
              )
            : this.translocoService.selectTranslate(`${this.errorMessageTranslationPathProviderToken}.${errorKey}`, errorParams)
          : this.translocoService.selectTranslate(`${this.defaultErrorMessagesTranslationsPath}.${errorKey}`, errorParams);

        const context = { $implicit: translatedErrorMessage$ };
        this.viewContainerRef.createEmbeddedView(this.templateRef, context);
      }
    });
  }
}
