import { Directive, HostBinding, inject, input, OnInit } from '@angular/core';
import { LocationStrategy } from '@angular/common';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Directive({
  standalone: true,
  selector: '[ciphrAppNavigationLink]',
  exportAs: 'ciphrAppNavigationLink',
  hostDirectives: [RouterLink, RouterLinkActive],
})
export class AppNavigationLinkDirective implements OnInit {
  private locationStrategy = inject(LocationStrategy);
  private routerLink = inject(RouterLink, { self: true });
  private routerLinkActive = inject(RouterLinkActive, { self: true });

  link = input.required<string>({ alias: 'ciphrAppNavigationLink' });

  @HostBinding('href') href?: string;

  get active(): boolean {
    return this.routerLinkActive.isActive;
  }

  ngOnInit() {
    const baseHref = this.locationStrategy.getBaseHref();

    if (this.link().startsWith(baseHref)) {
      this.routerLink.routerLink = this.link().replace(baseHref, '');
    } else {
      this.href = this.link();
    }
  }
}
