import { DestroyRef, Directive, ElementRef, inject, OnInit } from '@angular/core';
import { NgControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { fromEvent, switchMap } from 'rxjs';

import { TranslocoService } from '@ngneat/transloco';

import {
  ChrDatepickerDirective,
  ChrInputDirective,
  ChrMultiSelectDirective,
  ChrSelectDirective,
  ChrTextareaDirective,
} from '@ciphr-design-system/angular';

import { getInternalStatusChangeOfControl } from '@ciphr/libs/utils/forms';

@Directive({
  selector: '[ciphrValidationError]',
  standalone: true,
})
export class ValidationErrorDirective implements OnInit {
  private datepickerDirective = inject(ChrDatepickerDirective, { optional: true });
  private destroyRef = inject(DestroyRef);
  private elementRef = inject(ElementRef);
  private inputDirective = inject(ChrInputDirective, { optional: true });
  private multiSelectDirective = inject(ChrMultiSelectDirective, { optional: true });
  private ngControl = inject(NgControl);
  private selectDirective = inject(ChrSelectDirective, { optional: true });
  private textAreaDirective = inject(ChrTextareaDirective, { optional: true });
  private translocoService = inject(TranslocoService);

  ngOnInit(): void {
    const { control } = this.ngControl;
    const formDirective =
      this.datepickerDirective ?? this.inputDirective ?? this.multiSelectDirective ?? this.selectDirective ?? this.textAreaDirective;

    if (control && formDirective && !formDirective.readonly) {
      fromEvent(this.elementRef.nativeElement, 'blur')
        .pipe(
          switchMap(() => getInternalStatusChangeOfControl(control)),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe(() => {
          const { errors } = this.ngControl;

          if (errors) {
            const [errorKey, errorParams] = Object.entries(errors)[0];
            const upperSnakeCasedErrorKey = errorKey.replace(/[A-Z]/g, (letter) => `_${letter}`).toUpperCase();
            const validationTranslationKey = `VALIDATION.${upperSnakeCasedErrorKey}`;

            formDirective.explicitValidationMessage = this.translocoService.translate(validationTranslationKey, errorParams);
          } else {
            formDirective.explicitValidationMessage = '';
          }

          formDirective.error = !!errors;
        });
    }
  }
}
