import { inject, Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { DEFAULT_ERRORS_TRANSLATIONS_PATH, ErrorMessageTranslationPathProviderToken } from './validation.types';
import { TranslocoService } from '@ngneat/transloco';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Injectable({
  providedIn: 'root',
})
export class ValidationErrorMessageService {
  private errorMessageTranslationPathProviderToken = inject(ErrorMessageTranslationPathProviderToken, { optional: true });
  private defaultErrorMessagesTranslationsPath = inject(DEFAULT_ERRORS_TRANSLATIONS_PATH);

  private translocoService = inject(TranslocoService);
  private liveAnnouncer = inject(LiveAnnouncer);

  translateValidationError(errors: ValidationErrors, controlName: string | number): string {
    const [errorKey, errorParams] = Object.entries(errors)[0];

    const translations = this.translocoService.getTranslation(this.translocoService.getActiveLang());
    const translation = translations[`${this.errorMessageTranslationPathProviderToken}.${errorKey}.${controlName}`];

    return this.errorMessageTranslationPathProviderToken
      ? translation
        ? this.translocoService.translate(`${this.errorMessageTranslationPathProviderToken}.${errorKey}.${controlName}`, errorParams)
        : this.translocoService.translate(`${this.errorMessageTranslationPathProviderToken}.${errorKey}`, errorParams)
      : this.translocoService.translate(`${this.defaultErrorMessagesTranslationsPath}.${errorKey}`, errorParams);
  }

  announceA11Y(message: string): void {
    this.liveAnnouncer.announce(message);
  }
}
