import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { Portal, TemplatePortal } from '@angular/cdk/portal';

@Injectable({ providedIn: 'root' })
export class PageHeaderService {
  private actionsAreaPortal: WritableSignal<TemplatePortal | null> = signal(null);

  getActionsAreaPortal(): Signal<Portal<any> | null> {
    return this.actionsAreaPortal.asReadonly();
  }

  setActionsAreaPortal(portal: TemplatePortal): void {
    this.actionsAreaPortal.set(portal);
  }

  resetActionsPortal(): void {
    this.actionsAreaPortal.set(null);
  }
}
